import React from "react";
import "../../scss/components/product.scss"

const SquareProductEmbed = ( { title, description, price, image, buylink, buytext} ) => (
    <div className={ `product-embed-wrapper` }>
        <div className={ `inner` }>
            <img 
            src={ image } 
            alt={ title } 
            onerror="this.style.display='none'" />

            <div className={ `inner` }>
                <h3 className={ `title` }>{ title }</h3>
                <hr />
                <h4 class="price">{ price }</h4>
                <hr />
                <p className={ `description` }>{ description }</p>
                <a 
                    className={ `buy-link` }
                    target="_blank noreferrer" 
                    href={ buylink }
                >
                { buytext ? buytext : `Buy` }
                </a>
            </div>
        </div>
    </div>
)

export default SquareProductEmbed;