import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/index.scss"
import SquareProductEmbed from "../components/products/squareproductembed"

const StoreLandingPage = () => (
  <Layout template="subpage">
    <Seo
        title="M's Mini Farm Store"
        description={ `Welcome to Our Store, where we list the items we currently have for sale`}/>

    <section className={ `content-section bg-color-cream text-center` }>
        <div className={ `container-lg inner` }>
            <h1>Store</h1>
            <p className={ `intro-statement` }>
            Checkout the items we have for sale, it is an evergrowing list.
            </p>
        </div>
    </section>

    <section className={ `content-section bg-color-dark-slate text-center` }>
        <div className={ `container-full inner` }>
            <h2>Products</h2>
            <div className={ `product-block` }>
                <SquareProductEmbed 
                    title="Goatin Those Summer Vibes Tee"
                    description="Get your summer vibes with this bright goat-themed t-shirt, featuring Ash Gary (the goat), wearing some sweet summer shades. 100% Heavy Cotton Gildan. Youth and Adult Sizes Available."
                    image="https://items-images-production.s3.us-west-2.amazonaws.com/files/6dce820019548f6effa7f6e275ae1fc630682483/original.jpeg"
                    buylink="https://square.link/u/WYQmHNO6?src=embed"
                    price="20.00"
                />
            </div>
        </div>
    </section>

  </Layout>
)

export default StoreLandingPage
